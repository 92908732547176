import PropTypes from "prop-types"
import React from "react"
import logo from "../images/ailogo.png"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'

const handleOnSubmit = e => {
  e.preventDefault();
  console.log(e.target.query.value)
  window.location.replace("http://www.w3schools.com");

}


const Header = ({ siteTitle }) => (
  <header>    
    {/*style={{      background: `white`,      marginBottom: `1.45rem`,    }}  >    <h1 style={{ margin: 0 }}>        <Link          to="/"          style={{            color: `black`,            textDecoration: `none`,          }}        >          <img src={logo}/>          {/*{siteTitle}*//*}        </Link>      </h1>    <div      style={{        margin: `0 auto`,        maxWidth: 960,        padding: `1.45rem 1.0875rem`,      }}    >          </div>*/}
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
    <Navbar.Brand href="/">
      <img
        src={logo}
        width="160"
        height="30"
        style={{
          margin: 0,
          padding: 0
        }}
        alt="Aladdin International"
      />
      </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="#features">Weekly Ad</Nav.Link>
      <Nav.Link href="/hours">Hours & Location</Nav.Link>
      <NavDropdown title="Products" id="collasible-nav-dropdown">
        <NavDropdown.Item href="/products/?dept=grocery">Grocery</NavDropdown.Item>
        <NavDropdown.Item href="/products/?dept=produce">Produce</NavDropdown.Item>
        <NavDropdown.Item href="/products/?dept=homesupplies">Home Supplies</NavDropdown.Item>
        <NavDropdown.Item href="/products/?dept=pastries">Pastries</NavDropdown.Item>
        {/*<NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
      </NavDropdown>
    </Nav>
      {
        //TODO: SEARCH
        /*<Form
      onSubmit={handleOnSubmit}
      inline style={{
          margin: 0,
          padding: 0
        }}>
      <Form.Control type="text" name="query" placeholder="Search" className="mr-sm-2" />
      <Button type="submit" variant="outline-primary">Search</Button>
    </Form>*/
      }
    <Nav>
      <Nav.Link href="/">About us</Nav.Link>
      <Nav.Link eventKey={2} href="/contact">
        Contact us
      </Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
